import * as React from "react";
import type { HeadFC } from "gatsby";
import { Link } from "gatsby";
import Navbar from "../components/navbar/navbar";
import Footer from "../components/footer/footer";
import Highlight from "../components/highlight/highlight";
import logomark from "../images/logomark.svg";
import chair from "../images/chair.svg";

const ContactUsPage = () => {
    const CONTACT_US = process.env.GATSBY_CONTACT_US ?? "maildrop@heluna.com";

    return (
        <div className={"overflow-x-hidden"}>
            <Navbar />
            <main className={"relative z-0 dark:text-stone-50/75"}>
                <div className={"px-8 pt-16 pb-16 text-center md:container md:mx-auto"}>
                    <h1 className={"text-4xl font-bold text-stone-800 dark:text-stone-300 md:text-6xl"}>
                        Have Questions or Comments?
                    </h1>
                </div>
                <div className={"px-8 md:container md:mx-auto md:flex md:justify-center"}>
                    <div className={"md:flex-none md:basis-[50ch]"}>
                        <p className={"pb-8"}>
                            We're happy to answer any questions or discuss any comments you might have. First, though,
                            please take these notes into consideration.
                        </p>

                        <h2 className={"pb-8 pt-16 text-2xl font-bold text-stone-800 dark:text-stone-200 md:text-4xl"}>
                            Maildrop cannot send you email.
                        </h2>
                        <p className={"relative"} data-cypress={"cannotsend"}>
                            Maildrop is designed to accept email messages only, and{" "}
                            <Highlight>cannot send any messages</Highlight>. If you see any messages coming from
                            maildrop.cc, please ignore and delete them - someone else is spoofing maildrop.cc addresses.
                            There is no way to send a message from a Maildrop inbox to another email address.
                        </p>

                        <div className={"flex justify-center pt-16"}>
                            <img
                                src={logomark}
                                className={"w-12 opacity-5 dark:opacity-10 dark:invert"}
                                alt={"Maildrop logo"}
                            />
                        </div>

                        <h2 className={"pb-8 pt-16 text-2xl font-bold text-stone-800 dark:text-stone-200 md:text-4xl"}>
                            Messages in your inbox may disappear anytime.
                        </h2>
                        <p>
                            Due to the amount of incoming mail, older messages and inboxes that haven't received a
                            message in a while may be automatically deleted to make space. If you're looking for a
                            message that is older than 24 hours it is extremely likely that the message is gone.
                        </p>

                        <div className={"flex justify-center pt-16"}>
                            <img
                                src={logomark}
                                className={"w-12 opacity-5 dark:opacity-10 dark:invert"}
                                alt={"Maildrop logo"}
                            />
                        </div>

                        <h2 className={"pb-8 pt-16 text-2xl font-bold text-stone-800 dark:text-stone-200 md:text-4xl"}>
                            Anyone can read your Maildrop email.
                        </h2>
                        <p className={"relative pb-8"}>
                            Please see our{" "}
                            <Link
                                to={"/privacy"}
                                className={"text-rose-600 underline-offset-2 hover:text-rose-500 hover:underline"}>
                                privacy policy
                            </Link>{" "}
                            for more information. Since Maildrop doesn't require any logins or passwords, please do not
                            use your Maildrop inbox for anything that you would like to be kept private or secure.
                        </p>
                        <p>
                            Maildrop addresses are meant to be <Highlight>temporary and disposable</Highlight>.
                        </p>

                        <div className={"flex justify-center pt-16"}>
                            <img
                                src={logomark}
                                className={"w-12 opacity-5 dark:opacity-10 dark:invert"}
                                alt={"Maildrop logo"}
                            />
                        </div>

                        <h2 className={"pb-8 pt-16 text-2xl font-bold text-stone-800 dark:text-stone-200 md:text-4xl"}>
                            Maildrop greylists every email server.
                        </h2>
                        <p>
                            If Maildrop receives a message from an email server it hasn't communicated with yet, that
                            server goes onto something called a "greylist", where the server is told it needs to retry
                            the message after a given period of time. Due to this, messages from new (to Maildrop)
                            servers may be delayed by at least fifteen minutes, and often times up to an hour.
                        </p>

                        <div className={"flex justify-center pt-16"}>
                            <img
                                src={logomark}
                                className={"w-12 opacity-5 dark:opacity-10 dark:invert"}
                                alt={"Maildrop logo"}
                            />
                        </div>

                        <h2 className={"pb-8 pt-16 text-2xl font-bold text-stone-800 dark:text-stone-200 md:text-4xl"}>
                            Maildrop doesn't allow large messages.
                        </h2>
                        <p>
                            If you're trying to send large attachments to a Maildrop inbox, they will be refused.{" "}
                            <Highlight>The largest message allowed is 500k.</Highlight> Other attachments will be
                            deleted automatically.
                        </p>

                        <div className={"flex justify-center pt-16"}>
                            <img
                                src={logomark}
                                className={"w-12 opacity-5 dark:opacity-10 dark:invert"}
                                alt={"Maildrop logo"}
                            />
                        </div>
                    </div>
                </div>
                <div className={"flex flex-col px-8 pt-16 pb-32 md:container md:mx-auto md:flex-row"}>
                    <div className={"flex-1 basis-1/2 md:flex md:flex-row md:justify-end"}>
                        <div className={"md:w-full md:flex-none lg:w-[50ch]"}>
                            <h2 className={"pb-8 text-2xl font-bold text-stone-800 dark:text-stone-200 md:text-4xl"}>
                                Still have a question or comment?
                            </h2>
                            <p>
                                No problem - just email Maildrop at{" "}
                                <a
                                    href={`mailto:${CONTACT_US}`}
                                    className={
                                        "whitespace-nowrap text-rose-600 underline-offset-2 hover:text-rose-500 hover:underline"
                                    }>
                                    {CONTACT_US}
                                </a>{" "}
                                (yes, you're emailing a Maildrop inbox alias!).
                            </p>
                        </div>
                    </div>
                    <div className={"max-w-full flex-1 basis-1/2 md:flex md:justify-center"}>
                        <img
                            data-cypress={"chair"}
                            src={chair}
                            className={"w-full pt-16 opacity-90 dark:opacity-90 md:w-4/5 md:pt-0 lg:w-1/2"}
                            alt={"Woman sitting on a chair"}
                        />
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export const Head: HeadFC = () => (
    <>
        <title>Contact Us Page</title>
    </>
);

export default ContactUsPage;
